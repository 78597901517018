import { h } from 'preact';
import style from './style.scss';

const Header = () => (
	<header class={style.header}>
    <a class={style['coll-logo']} href="https://thecollectionbarcelona.com"><img src="https://collection-static.vercel.app/assets/collection_logo.svg" /></a>
	</header>
);

export default Header;
